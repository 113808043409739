import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Home from './components/Home.js';
import NotPage from "./components/NotPage";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element = { <NotPage /> }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;