import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import logofrog from '../ŁitecoinFrogs_logo.png';
import Button from 'react-bootstrap/Button';
import pepeImage from '../pepe.jpg';
import {frogsDataMagic,frogsDataPixel} from './frogdata.js';
import  React,{Component, useState} from 'react';

function dealTextShow(content){
    if(content.length > 15){
        return content.substr(0,6) + '...' + content.substr(content.length-6,6);
    }
    return content;
}

function showMintProgress(){
    const minted = frogsDataMagic.length + frogsDataPixel.length;
    return `${minted}/10000`;
}

const FrogCard = (frog) => (
    <Col>
        <Card style={{ width: '8rem', backgroundColor: 'black' }}>
            <Card.Img variant="top" src={require(`./fragimages/${frog.title}.jpg`)} />
            <Card.Body>
            <Card.Title>{frog.title}</Card.Title>
            <Card.Text>
                {dealTextShow(frog.address)}
            </Card.Text>
            <Button variant="secondary" onClick={ () => {
                    const w=window.open('about:blank');
                    w.location.href=`https://ordinalslite.com/inscription/${frog.link}`
            } }>Verify</Button>
            </Card.Body>
        </Card>
    </Col>
)

const Home = () => {

    const [showModal, setShowModal] = useState(false);

    const frog_nft_fee_address = 'ltc1qenc5pq0uehcrw207mjz4xgwys2gyms503rx206';

    const reversedFrogsDataMagic = frogsDataMagic.map((item, index, array) => array[array.length - 1 - index]);
    const reversedFrogsDataPixel = frogsDataPixel.map((item, index, array) => array[array.length - 1 - index]);

    return (
        <>
        <Container fluid style={{ background: 'black',color: 'white' }} >
          <Row style={{ marginLeft: '30px', marginRight: '30px' }}>
            <Col md = {{ }}>
              <img style={{ width: '250px', height: '35px' ,marginTop: '30px', marginBottom: '15px'}} src={logofrog} alt='tel' />
            </Col>
            <Col style={{ textAlign: 'right', marginBottom: '30px', marginTop: '30px' }}>
                    {/* <Button variant="outline-success" id="connectButton" disabled style={{ width: '120px',marginRight: '15px', overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} > { showMintProgress() } </Button>{' '} */}
                    <Button variant="secondary" id="connectButton" onClick={() => setShowModal(true)} style={{ width: '100px', overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} >Mint</Button>{' '}
                    <Button variant="secondary" id="connectButton" style={{ width: '100px',marginLeft: '15px' ,overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={ () => {
                            const w=window.open('about:blank');
                            w.location.href='https://twitter.com/litecoinfrogs'
                        }}>
                        Twitter
                    </Button>{' '}    
            </Col>
          </Row>
    
            <Row style={{ marginLeft: '30px', marginRight: '30px' }} >
                <Col className="d-flex justify-content-center align-items-center">
                    <img src={pepeImage} style={{ width: '120px', height: '120px' ,marginTop: '20px', marginBottom: '20px', borderRadius: '10px'}} alt="pepe" />
                </Col>
            </Row>
    
            <Row style={{ marginLeft: '30px', marginRight: '30px' }}>
            <Col md={{}} style={{ textAlign: 'center', marginTop: '20px' }} >
                <p>
                    The inspiration for LitcoinFrogs comes from Pepe, with 10,000 frogs living on the Litcoin blockchain.
                </p>
            </Col>
          </Row>
    
            <Row style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Col>
                <div style={{marginLeft: '0px', marginRight: '0px',marginTop: '50px',marginBottom: '30px', height: '.5px', backgroundColor: 'gray'}}></div>
                </Col>
            </Row>
    
            <Row style={{ marginLeft: '30px', marginRight: '30px'}}>
                <Col md={{}} style={{ textAlign: 'left', marginTop: '0px', fontSize: '22px' }} >
                    <p>
                        Verified Holders
                    </p>
                </Col>
                <Col md={{}} style={{ textAlign: 'right', marginTop: '0px', fontSize: '16px', color: 'green', fontWeight: 'bold' }} >
                    Running
                    <Button variant="outline-success" id="connectButton" active={true} style={{ cursor: 'default',width: '80px',marginLeft: '20px',marginLeft: '30px' ,overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={ () => {}}>
                        Pixels
                    </Button>{' '}
                </Col>
            </Row>
            
            <Row style={{ marginLeft: '30px', marginRight: '30px', marginTop: '30px' }}>
                    {
                        reversedFrogsDataPixel.map((frog) => (
                            <FrogCard image={frog.image} title={frog.title} address={frog.address} link={frog.link} />
                        ))
                    }
            </Row>

            <Row style={{ marginLeft: '250px', marginRight: '250px' }}>
                <Col>
                <div style={{marginLeft: '0px', marginRight: '0px',marginTop: '50px',marginBottom: '0px', height: '.5px', backgroundColor: 'gray'}}></div>
                </Col>
            </Row>

            <Row style={{ marginLeft: '30px', marginRight: '30px', marginTop: '30px'}}>
                <Col md={{}} style={{ textAlign: 'right', marginTop: '0px', fontSize: '16px', color: 'green', fontWeight: 'bold' }} >
                    Sold out
                    <Button variant="outline-success" id="connectButton" active={true} style={{ cursor: 'default',width: '80px',marginLeft: '20px',marginLeft: '30px' ,overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} onClick={ () => {}}>
                        Magic
                    </Button>{' '}
                </Col>
            </Row>

            <Row style={{ marginLeft: '30px', marginRight: '30px', marginTop: '30px' }}>
                    {
                        reversedFrogsDataMagic.map((frog) => (
                            <FrogCard image={frog.image} title={frog.title} address={frog.address} link={frog.link} />
                        ))
                    }
            </Row>
    
            <Row style={{ marginLeft: '30px', marginRight: '30px' }}>
                <Col>
                <div style={{marginLeft: '0px', marginRight: '0px',marginTop: '50px',marginBottom: '0px', height: '.5px', backgroundColor: 'gray'}}></div>
                </Col>
            </Row>
    
          <Row style={{ color: 'gray', marginTop: '30px',height: '40px', marginBottom: '0px', marginLeft: '30px', marginRight: '30px',textAlign: 'center' }}>
          <Col>Copyright © 2023 ŁitecoinFrogs</Col>
          </Row>
      
        </Container> 
        {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <div
            style={{
              width: 700,
              height: 620,
              backgroundColor: 'rgb(97,150,77)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <div style={{ marginTop: '40px'}} >
                <img src={pepeImage} 
                    style={{ 
                        width: 80,
                        height: 80,
                        borderRadius: '50%',
                        animation: `${showModal ? 'rotation 6s infinite linear' : ''}`, // added conditional rendering of animation
                    }}
                 />
                 {/* CSS animation for rotating the image */}
                <style>
                {showModal && // added conditional rendering of style
                    `@keyframes rotation {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                    }`
                }
                </style>
            </div>
            <div style={{ color: 'white', fontSize: '16px',textAlign: 'center', marginTop: '10px' }}>
                Send the fee to this address:<br />
                {frog_nft_fee_address}<br />
            </div>
            <div style={{ color: 'white', marginTop: '15px',marginLeft: '15px', marginRight: '15px', fontSize: '16px', textAlign: 'center' }}>
                <img src={ require('../frog_nft_fee.png')} style={{ width: '150px', height: '150px', borderRadius: '15px' }} ></img> <br />
            </div>
            <div style={{ color: 'white', marginTop: '12px',marginLeft: '15px', marginRight: '15px', fontSize: '16px', textAlign: 'center' }}>
                Within 24 hours, your address received NFT.
            </div>
            <div style={{ color: 'white', marginTop: '15px',marginLeft: '30px', marginRight: '15px', fontSize: '16px', textAlign: 'left' }}>
                Note: <br />
                1. Frogs distribute in blind boxes. <br />
                2. Each frog is worth 0.1 LTC, and you can pay more than one fee at a time. <br />
                3. Be sure to use your wallet for payment and the transfer address will receive NFT. <br />
                4. When you receive NFT, try to freeze it and avoid treating NFT as LTC expense. <br />
            </div>

            <div style={{ marginTop: '15px' }}>
                <Button variant="secondary" style={{ width: '100px', marginTop: '15px', marginBottom: '15px' }} onClick={() => setShowModal(false)}>Close</Button>{' '}
            </div>

          </div>
        </div>
      )}
        </>
      );
}

  export default Home;